import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import ErrorPage from "../pages/error-page";

import { routes } from "./routes";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout pageContext={{ layout: "" }} />,
    errorElement: <ErrorPage />,
    children: routes,
  },
]);

const MainNavigation = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default MainNavigation;
