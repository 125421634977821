import React from "react";
import { Title } from "../Core";
import { Link } from "react-router-dom";
import imgLogo from "../../assets/image/logo.png";

const Logo = ({ color = "front", height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      {/* <Title color={color} variant="cardLg" className="mb-0">
        sfolio.
      </Title> */}
      <img src={imgLogo} alt="logo" className="img-fluid" width="90" />
    </Link>
  );
};

export default Logo;
