import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";

import imgPhoto from "../../assets/image/jpg/masonry-image-1.jpg";
import { Section, Text, Title, Button, Box } from "../Core";
import Divider from "../Divider";

const ImgDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;

  & > img {
    max-width: 70%;
    object-fit: contain;
    height: 80%;
    width: 100%;
    object-position: center;
  }
`;

function WorkItem({
  img = imgPhoto,
  title = "BRANDING",
  shortDescription = "Adaptable but Identifiable Product for Kandinsky.",
  description = `To satisfy our goal of creating a brand that could adapt
  across many use cases, we took the base form of their new
  logo and used it as an artboard. As long as the structural
  outline of the mark stayed consistent their brand was
  allowed to flex and adapt to specific uses. And by
  introducing these illustrative elements to the logomark,
  Strictly found it’s voice.`,
  urlPath = "/",
  isExternal = false,
  client = "Unknown",
  timeToWorked = "Apr 14, 2020",
}) {
  return (
    <Box bg="light">
      <Row style={{ paddingTop: 20 }}>
        <Col lg="5">
          <ImgDiv>
            <img src={img} alt="folio" />
          </ImgDiv>
        </Col>
        <Col lg="7">
          <Section hero bg="light">
            <Container>
              <Row>
                <Col lg="10">
                  <Text className="text-dark" variant="tag">
                    {title}
                  </Text>
                  <Title variant="secSm" color="#000" className="my-4">
                    {shortDescription}
                  </Title>
                  <Text
                    className="text-dark"
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    {description}
                  </Text>
                </Col>
              </Row>
            </Container>
          </Section>
          {/* <Section bg="light">
            <Container>
              <Row className="justify-content-center align-items-center">
                <Col lg="4" md="4" xs="6" className="mb-4 mb-lg-0 text-center">
                  <Text variant="tag" className="text-dark">
                    Client
                  </Text>
                  <Title variant="cardBig" color="#000" className="mt-3">
                    {client}
                  </Title>
                </Col>
                <Col lg="4" md="4" xs="6" className="mb-4 mb-lg-0 text-center">
                  <Text variant="tag" className="text-dark">
                    Time
                  </Text>
                  <Title variant="cardBig" color="#000" className="mt-3">
                    {timeToWorked}
                  </Title>
                </Col>
                <Col lg="4" md="4" xs="6" className="mb-4 mb-lg-0 text-center">
                  <Link
                    to={isExternal ? urlPath : `/work/${urlPath}`}
                    target={isExternal ? "_blank" : ""}
                  >
                    <Button arrowRight>Live work</Button>
                  </Link>
                </Col>
              </Row>
            </Container>
          </Section> */}
        </Col>
      </Row>
      <Divider />
    </Box>
  );
}

export default WorkItem;
