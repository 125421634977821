import React from "react";
import styled from "styled-components";

const DividerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const DividerContain = styled.div`
  background: ${({ theme }) => theme.colors.secondary};
  height: 4px;
  border-radius: 25px;
`;

function Divider({ width = "80%" }) {
  return (
    <DividerContainer>
      <DividerContain style={{ width: width }} />
    </DividerContainer>
  );
}

export default Divider;
