import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { device } from "../../utils";
import { Box, Title } from "../Core";

const BoxFooter = styled(Box)`
  z-index: -50;
  background-color: ${({ theme }) => theme.colors.primary};

  > div.container > .text-center > h4 {
    font-size: 0.8125rem;
    @media ${device.md} {
      font-size: 1.3125rem;
    }
  }
`;

const Footer = ({ isDark = true }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <BoxFooter pt="3.75rem" pb="3.75rem" className="position-relative">
        <Container>
          <div className="text-center">
            <Title
              variant="card"
              css={`
                font-size: 0.8125rem;
              `}
              className="text-uppercase text-light"
            >
              Nice Adventures © {new Date().getFullYear()} All right reseved
            </Title>
          </div>
        </Container>
      </BoxFooter>
    </>
  );
};

export default Footer;
