export const landingData = [
  {
    img: require("../assets/image/png/chatGPT.png"),
    title: "Chat GPT",
    shortDescription: "Automating Work with Chat GPT",
    description: `Using ChatGPT, we've boosted developer productivity as a peer programmer tool and designed a custom frontend with 'Prompt Templates' for seamless content generation. Our expertise showcases our ability to harness AI technology for innovative solutions.`,
    urlPath: "chat-gpt",
    isExternal: false,
    client: "Unknown",
    timeToWorked: "Jan 01, 2023",
  },
  {
    img: require("../assets/image/png/cobru.png"),
    title: "Cobru",
    shortDescription: "Digital Wallet and Payment Provider",
    description: `Cobru is a digital wallet and Payment provider Application that operates in Colombia.

    We were part of the initial internal team helping take the project from concept to production. And through the years have continued to support the platform development as external contractors.Using ChatGPT, we've boosted developer productivity as a peer programmer tool and designed a custom frontend with 'Prompt Templates' for seamless content generation. Our expertise showcases our ability to harness AI technology for innovative solutions.`,
    urlPath: "cobru",
    isExternal: false,
    client: "Trixel",
    timeToWorked: "Apr 14, 2020",
  },
  {
    img: require("../assets/image/png/fiatConnect.png"),
    title: "Fiat Connect",
    shortDescription: "Fiat Connect Integration",
    description: `Our team proudly contributed to the development of the FiatConnect API Standard, streamlining integration for web3 wallets and dapps with multiple on/off ramps. We played a key role in adding 'user initiated transactions' to the specification, shaping this global solution for seamless cash-in and cash-out interactions between providers.`,
    urlPath: "fiat-connect",
    isExternal: false,
    client: "Trixel & Fiat Connect",
    timeToWorked: "Nov 01, 2022",
  },
  {
    img: require("../assets/image/png/chivo.png"),
    title: "Chivo Wallet",
    shortDescription: "The El Salvador Official Crypto Wallet",
    description: `El Salvador was the first country to make bitcoin legal tender, and we were part of the team that developed the first version of Chivo Wallet mobile Application, even offering support a couple of months after its release in September 2021.`,
    urlPath: "chivo-wallet",
    isExternal: false,
    client: "Chivo Wallet",
    timeToWorked: "Apr 14, 2020",
  },
];
