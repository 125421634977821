import React, { useContext } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import { Section, Title, Text } from "../../components/Core";
import imgPhoto from "../../assets/image/png/aboutUS.png";
import GlobalContext from "../../context/GlobalContext";

const LinkSocial = styled.a`
  color: ${({ theme }) => theme.colors.light} !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 1.63px;
  transition: 0.4s;
  &:hover {
    color: ${({ theme }) => theme.colors.light} !important;
    transform: translateY(-5px);
  }
`;

const AboutSection = ({ hero = true, ...rest }) => {
  const gContext = useContext(GlobalContext);
  const bg = gContext.theme.bodyDark ? "dark" : "light";
  const colorText = gContext.theme.bodyDark ? "light" : "dark";
  return (
    <>
      <Section hero={hero} bg={bg} {...rest}>
        <Row className="justify-content-center">
          <Col xs="11" md="10">
            <Row className="align-items-center justify-content-center">
              <Col xl="4" lg="5" md="5" xs="8">
                <div className="text-center">
                  <img src={imgPhoto} alt="folio" className="img-fluid" />
                </div>
              </Col>
              <Col xl="8" lg="7" md="12">
                <div className="pl-lg-4 pt-5 pt-lg-0">
                  <Title color={colorText} variant="secSm">
                    Nice Adventures
                  </Title>
                  <Text
                    color={colorText}
                    className="mt-3 mt-lg-5"
                    css={`
                      line-height: 1.5;
                    `}
                  >
                    Founded by{" "}
                    <Link
                      to="https://www.linkedin.com/in/hsortiz"
                      className="font-weight-bold"
                      target="_blank"
                    >
                      Hender Ortiz
                    </Link>{" "}
                    and{" "}
                    <Link
                      to="https://orla.games"
                      target="_blank"
                      className="font-weight-bold"
                    >
                      Orlando Almario
                    </Link>
                    , <span className="font-weight-bold">Nice Adventures</span>{" "}
                    is a close-knit team of expert developers and designers
                    collaborating on various projects. Based in Barranquilla,
                    Colombia, our passion lies in delivering high-quality work
                    and having happy customers
                  </Text>
                  <Text color={colorText} className="mt-4">
                    Our software development services not only cater to your
                    needs but also fuel our other passion: creating adventure
                    video games. If you're a fan, be sure to check them out!
                  </Text>

                  <Text color={colorText} className="mt-4">
                    We look forward to working together and embarking on
                    exciting new adventures.
                  </Text>
                  {/* <div className="mt-4">
                    <Text color="light">Email me at</Text>

                    <Text variant="p">
                      <a
                        href="mailto:hello@folio.com"
                        className="font-weight-bold"
                      >
                        <Span color="light">hello@folio.com</Span>
                      </a>
                    </Text>
                  </div>
                  <div className="mt-5">
                    <img src={imgSignLight} alt="sign" className="img-fluid" />
                  </div>
                  <Text color="light" className="mt-3 font-weight-bold">
                    Bruce Ryan
                  </Text>
                  <Text color="light" variant="small">
                    Founder of Folio Digital
                  </Text> */}
                  <div className="mt-5 d-flex">
                    <LinkSocial
                      href="mailto:contact@niceadventures.co"
                      target="_blank"
                      className="mr-3"
                    >
                      contact@niceadventures.co
                    </LinkSocial>
                    <LinkSocial
                      href="https://www.upwork.com/agencies/1615502808848707584/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className=" mr-3"
                    >
                      upwork/nice-adventures
                    </LinkSocial>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default AboutSection;
