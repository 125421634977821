import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-scroll";

import { Title, Section, Box, Text, Button } from "../../components/Core";

const TitleSection = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section hero className="position-relative">
        <Container>
          <Row className="justify-content-center text-center">
            <Col lg="10">
              <Box>
                <Text variant="tag">Transforming ideas into success</Text>
                <Title className="my-4">
                  We communicate clearly and craft software solutions that
                  deliver results
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  With a decade of expertise, our software developers have
                  crafted web and mobile solutions enjoyed by hundreds of
                  thousands. Specializing in payment platforms, crypto
                  exchanges, diverse mobile apps, and cutting-edge AI tools like
                  ChatGPT, we're eager to discuss your needs.
                </Text>
              </Box>
            </Col>
          </Row>

          {/* <Box pt={["4rem", null, null, "6.25rem"]}>
            <img src={imgWorkCover} alt="" className="img-fluid w-100" />
          </Box> */}

          <Box mt="52px" className="text-center">
            <Link
              to="works-nice-adventures"
              spy={true}
              smooth={true}
              offset={-50}
              duration={1000}
            >
              <Button arrowRight>Explore works </Button>
            </Link>
          </Box>
        </Container>
      </Section>
    </>
  );
};

export default TitleSection;
