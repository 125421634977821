import AboutPage from "../pages/about";
import IndexPage from "../pages/landing";

export const routes = [
  {
    path: "",
    element: <IndexPage />,
    name: "Inicio",
  },
  {
    path: "/about-us",
    element: <AboutPage />,
    name: "about-us",
  },
];
