import React from "react";
import { Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import About from "../sections/about/About";

function AboutPage() {
  return (
    <>
      <PageWrapper darkTheme={false}>
        <Box>
          <About />
        </Box>
      </PageWrapper>
    </>
  );
}

export default AboutPage;
