import React, { useEffect } from "react";
import { Element, scroller } from "react-scroll";

import PageWrapper from "../components/PageWrapper";
import WorkItem from "../components/WorkItem/WorkItem";
import TitleSection from "../sections/home/TitleSection";

import { landingData } from "../data/landingData";
import Contact from "../sections/home/Contact";
import { useLocation } from "react-router-dom";

function IndexPage(props) {
  const { state } = useLocation();

  useEffect(() => {
    if (state?.scrolling) {
      scroller.scrollTo("contact-us", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [state]);

  return (
    <>
      <PageWrapper darkTheme={false}>
        <TitleSection />
        <Element name="works-nice-adventures">
          {landingData.map((work, index) => (
            <div key={index}>
              <WorkItem key={index} {...work} />
            </div>
          ))}
        </Element>

        <Element name="contact-us">
          <Contact />
        </Element>
      </PageWrapper>
    </>
  );
}

export default IndexPage;
