import React from "react";
import { GlobalProvider } from "./context/GlobalContext";
import MainNavigation from "./navigation/MainNavigation";

function App() {
  return (
    <GlobalProvider>
      <MainNavigation />
    </GlobalProvider>
  );
}

export default App;
